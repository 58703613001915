import { useState, useEffect, ChangeEvent } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  Button,
  TextField,
  IconButton,
  FormControl,
  Table,
  Box,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
} from '@mui/material'
import School from '@mui/icons-material/School'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { CustomPagination } from '../../../../../components/CustomPagination'

import MaintenanceClassForm from '../MaintenanceStudentForm'
import DeleteClassForm from '../DeleteStudentForm'
import BasicModal from '../../../../../components/ModalManagement/BasicModal'
import { IStudents } from '../../types'

import StudentsService from '../../../../../services/students'
import ClassesService from '../../../../../services/classes'

import { Alert } from '../../../../../components/Alert'

export interface CardRowData {
  id: string
  name: string
  email: string
  accessSystems: Array<string>
  profiles: any
  inclusionDate: string
  status: boolean
  statusAction: (user: any) => Promise<any>
  editAction: (user: any) => Promise<any>
  deleteAction: (user: any) => Promise<any>
}

export interface CardRowDataProfile {
  id: string
  name: string
  accessSystems: Array<string>
  profiles: Array<string>
  inclusionDate: string
  status?: boolean
  statusAction?: (profile: any) => Promise<any>
  editAction: (profile: any) => Promise<any>
  deleteAction: (profile: any) => Promise<any>
}
interface ITableManageStudents {
  idTurma: string
}
const TableManageStudents: React.FC<ITableManageStudents> = ({ idTurma }) => {

  const rowsPerPage = 10
  const { getFiltredStudent, postStudent, putStudent, deleteStudent } =
    StudentsService()
  const [students, setStudents] = useState<Array<IStudents>>([])
  const [openModalMaintenance, setOpenModalMaintenance] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [actionTitle, setActionTitle] = useState<string>('Adicionar aluno')
  const [studentInAction, setStudentInAction] = useState<IStudents>(
    {} as IStudents
  )

  const [searchKey, setSearchKey] = useState('')
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  const [nomeTurma, setNomeTurma] = useState('')

  const { getClassById } = ClassesService()

  const handleClickAddStudent = () => {
    setOpenModalMaintenance(true)
    setActionTitle('Adicionar aluno')
  }

  const handleClickEditStudent = (studentData: IStudents) => {
    setStudentInAction(studentData)
    setActionTitle('Editar aluno')
    setOpenModalMaintenance(true)
  }

  const handleSubmitMaintenanceForm = async (data: IStudents) => {
    const foundIndex = students.findIndex(
      (s) => s.id_aluno === data.id_aluno
    )
    
    if (foundIndex === -1) {
      data.nome_turma = nomeTurma
      await postStudent({ ...data, id_turma: idTurma }).then(() => {
        Alert({
          type: 'success',
          message: 'Aluno criado com sucesso!',
          autoClose: true,
        })
      }).catch(() => {
        Alert({
          type: 'error',
          message: 'Erro ao criar aluno!',
          autoClose: true,
        })
      })
    } else {
      await putStudent({ ...data, id_turma: idTurma }).then(() => {
        Alert({
          type: 'success',
          message: 'Aluno editado com sucesso!',
          autoClose: true,
        })
      }).catch(() => {
        Alert({
          type: 'error',
          message: 'Erro ao editar aluno!',
          autoClose: true,
        })
      })
    }

    setOpenModalMaintenance(false)
  }

  const handleClickDeleteStudent = (studentData: IStudents) => {
    setStudentInAction(studentData)
    setOpenModalDelete(true)
    setActionTitle(
      `Tem certeza que deseja excluir o(a) aluno(a) ${studentData.nome_aluno}?`
    )
  }

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(1)
    setSearchKey(event.target.value)
  }

  const handleDeleteStudent = async (studentData: IStudents) => {
    const foundIndex = students.findIndex(
      (s) => s.cod_matricula === studentData.cod_matricula
    )
    if (foundIndex === -1) return
    await deleteStudent(studentData.id_aluno)
    setOpenModalDelete(false)
  }

  useEffect(() => {
    getClassById(idTurma).then((res) => {
      setNomeTurma(res.data.nome_turma)
    })
  })

  useEffect(() => {
    if (openModalMaintenance || openModalDelete) return
    setStudentInAction({} as IStudents)
  }, [openModalMaintenance, openModalDelete])

  useEffect(() => {
    const delayTime = !searchKey ? 0 : 200
    const requestDelay = setTimeout(async () => {
      await getFiltredStudent(
        idTurma,
        rowsPerPage,
        searchKey,
        rowsPerPage * (page - 1)
      ).then(({ data }) => {
        setPageCount(Math.ceil(data.count / rowsPerPage))
        setStudents(data.results)
      })
    }, delayTime)
    return () => clearTimeout(requestDelay)
  }, [searchKey, openModalDelete, openModalMaintenance, page, idTurma])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '644px',
          padding: '24px',
          borderRadius: '4px',
          background: '#fff',
          paddingX: '16px',
          width: `calc(100vw - 4vw)`,
          '@media (min-width: 768px)': {
            width: 'auto',
            marginX: '5%',
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '32px',
              width: '100%',
              '@media (max-width: 768px)': {
                flexDirection: 'column',
                gap: '16px',
                marginBottom: '24px',
              },
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <School
                style={{
                  color: '#083CA6',
                  background: '#F2F5FD',
                  width: '48px',
                  height: '48px',
                  padding: '12px',
                  borderRadius: '50%',
                  marginRight: '16px',
                }}
              />
              <Typography
                style={{
                  fontSize: '2rem',
                  color: '#083CA6',
                }}
              >
                Alunos
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                justifyContent: 'space-between',
                '@media (max-width: 768px)': {
                  width: '100%',
                  flexDirection: 'column',
                },
              }}
            >
              <FormControl
                sx={{
                  '@media (min-width: 768px)': { width: '350px' },
                  width: '100%',
                }}
                size='small'
              >
                <TextField
                  onChange={handleChangeFilter}
                  label='Pesquise por matrícula, nome, RG ou turma'
                  inputProps={{ style: { height: '7px' } }}
                  sx={{ '& label[data-shrink="false"]': { top: '-7px' } }}
                />
              </FormControl>

              <Button
                variant='contained'
                disableElevation
                startIcon={<AddIcon />}
                style={{
                  backgroundColor: '#083CA6',
                  textTransform: 'none',
                  fontWeight: '700',
                }}
                className=''
                onClick={handleClickAddStudent}
              >
                Adicionar aluno
              </Button>
            </Box>
          </Box>
          <TableContainer className='mb-[24px]'>
            <Table
              sx={{
                tableLayout: 'fixed',
                border: '2px solid #F7F8FA',
                width: '1200px',
                '@media (min-width: 768px)': {
                  width: '100%',
                },
              }}
              padding='none'
            >
              <TableHead>
                <TableRow style={{ height: '48px', background: '#F7F8FA' }}>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='10%'
                    align='left'
                  >
                    Matrícula
                  </TableCell>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='10%'
                    align='left'
                  >
                    Nome do aluno
                  </TableCell>

                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='10%'
                    align='left'
                  >
                    Data de nasciemnto
                  </TableCell>

                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='10%'
                    align='left'
                  >
                    RG
                  </TableCell>

                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='12%'
                    align='center'
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student) => (
                  <TableRow key={student.id_aluno}>
                    <TableCell
                      sx={{ padding: '0 12px' }}
                      className='truncate ...'
                    >
                      {student.cod_matricula || '--'}
                    </TableCell>

                    <TableCell
                      sx={{ padding: '0 12px' }}
                      className='truncate ...'
                    >
                      {student.nome_aluno || '--'}
                    </TableCell>

                    <TableCell
                      sx={{ padding: '0 12px' }}
                      className='truncate ...'
                    >
                      {student.dt_nascimento || '--'}
                    </TableCell>

                    <TableCell
                      sx={{ padding: '0 12px' }}
                      className='truncate ...'
                    >
                      {student.rg || '--'}
                    </TableCell>

                    <TableCell sx={{ padding: '0 12px' }} align='right'>
                      <FormControlLabel
                        control={
                          <IconButton
                            size='small'
                            aria-label='Editar'
                            onClick={() => handleClickEditStudent(student)}
                            disableRipple
                          >
                            <EditIcon sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={''}
                      />
                      <FormControlLabel
                        control={
                          <IconButton
                            size='small'
                            aria-label='Excluir'
                            onClick={() => handleClickDeleteStudent(student)}
                            disableRipple
                          >
                            <DeleteIcon color='error' sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={''}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display='flex' justifyContent='right'>
          <CustomPagination
            variant='outlined'
            shape='rounded'
            count={pageCount}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        </Box>
      </Box>
      <BasicModal
        title={actionTitle}
        open={openModalMaintenance}
        close={setOpenModalMaintenance}
      >
        <MaintenanceClassForm
          confirmButtonLabel={actionTitle}
          data={studentInAction}
          onConfirm={handleSubmitMaintenanceForm}
          onCancel={() => setOpenModalMaintenance(false)}
        />
      </BasicModal>
      <BasicModal
        title={actionTitle}
        open={openModalDelete}
        close={setOpenModalDelete}
      >
        <DeleteClassForm
          data={studentInAction}
          onConfirm={handleDeleteStudent}
          onCancel={() => setOpenModalDelete(false)}
        />
      </BasicModal>
    </>
  )
}

export default TableManageStudents
