import { useEffect, useState } from "react";

import BasicModal from "../ModalManagement/BasicModal";
import { ProfileItem } from "./ProfileItem";

import { listProfiles } from "../../api/profile";


type ChangingProfileProps = {
  close: () => void
  hidden: boolean
}

interface listProfilesProps{
  escolas: string[];
  ids: number[];
  perfil: string;
}

export function ChangingProfile({close, hidden}: ChangingProfileProps) {
  const [profiles, setProfiles] = useState<listProfilesProps[]>([])

  useEffect(() => {
    listProfiles().then((res) => {
      setProfiles(res.results)
    })
  }, [])

  return (
    <BasicModal title="Alternar perfil de usuário" open={!hidden} close={close}>
      <div>
        {profiles.map((profile: listProfilesProps) => {
          return (
            profile.escolas?.map((school: string, index: number) => {
              return(
                <ProfileItem
                  profile={profile.perfil}
                  school={school}
                  id={profile.ids[index]}
                  handleClose={close}
                />
              )
            })
          )
        })} 
      </div>
    </BasicModal>
  )
}