import httpScanner from '../http/scanner-prova'
import { getLocalToken, getUserProfile } from './localStorage'

export default function AuthGovService() {
  const getUrl = async (queryParams: object) => {
    const api = await httpScanner.post('/govbr/authorize/', queryParams)
    return api
  }

  const getAuthTokens = async (queryParams: object) => {
    const api = await httpScanner.post('/govbr/', queryParams)
    return api
  }

  const getUserData = async () => {
    const userProfile = getUserProfile()
    const token = getLocalToken()

    const api = await httpScanner.get('/govbr/user/v2/info/5/', {
        headers: {
          Authorization: `Bearer ${token}`,
          'id-profile': userProfile
          
        }
      }
    )
    return api
  }

  return {
    getUrl,
    getAuthTokens,
    getUserData,
  }
}
