import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'

import jwt_decode from 'jwt-decode'
import { Alert } from '../components/Alert'

import {
  getHash,
  getLocalToken,
  getSystemUrl,
  getUserProfile,
  removeLocalToken,
  removeSystemUrl,
  setHash,
  setLocalRefreshToken,
  setLocalToken,
  setSystemUrl,
  setUserProfile,
} from '../services/localStorage'
import AuthGovService from '../services/auth-gov'

import loginLeft from '../assets/images/loginBanner.jpg'
import { Loader } from '../components/Loader'
import { listProfiles } from '../api/profile'
import { setUserData } from '../utils/setUserData'

export interface JWT {
  exp: number
}

export function Login() {
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState('')
  const [url, setUrl] = useState('')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [sysUrl, setSysUrl] = useState('')

  useEffect(() => {
    const fetchUrl = async () => {
      const params: any = queryString.parse(window.location.search)
      const cookUrl = await getSystemUrl()

      if (params.url) {
        await setSystemUrl(params.url)
        setSysUrl(params.url)
      }

      if (cookUrl) {
        await setSysUrl(cookUrl)
      }
    }

    fetchUrl().then(async () => {
      document.title = 'Login'
      setToken(getLocalToken())
      if (token) {
        try {
          const jwt: JWT = jwt_decode(token)

          if (Date.now() >= jwt.exp * 1000) {
            removeLocalToken()
          }
          if (sysUrl || getSystemUrl() !== '') {
            await removeSystemUrl()
            
            setUserData()
            window.location.href = `https:\\//${sysUrl || getSystemUrl()}`
            return
          }

          setUserData()
          navigate('/pre-select')
        } catch (err) {
          console.log(err)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token])

  useEffect(() => {
    const params = queryString.parse(window.location.search)
    if (params.code) {
      setLoading(true)
      getTokens(
        params.code,
        (process.env.REACT_APP_MODE === "development") ? "https://develop-login-integracao-dot-scanner-prova.rj.r.appspot.com/login" : 'https://plataforma-integrada.nees.ufal.br/login',
      ).then(async () => {
        const cookUrl = await getSystemUrl()
        if (cookUrl !== '') {
          await removeSystemUrl()

          setUserData()
          window.location.href = `https:\\//${cookUrl}`
          return
        } else {
          setUserData()
          return navigate('/pre-select')
        }
      })
    } else {
      setLoading(false)
      AuthGovService()
        .getUrl({
          redirect_uri: (process.env.REACT_APP_MODE === "development") ? "https://develop-login-integracao-dot-scanner-prova.rj.r.appspot.com/login" : 'https://plataforma-integrada.nees.ufal.br/login',
        })
        .then((res) => {
          setUrl(res.data.url)
          setHash(res.data.hash)
        })
        .catch((err) => {
          AuthGovService()
          .getUrl({
            redirect_uri: (process.env.REACT_APP_MODE === "development") ? "https://develop-login-integracao-dot-scanner-prova.rj.r.appspot.com/login" : 'https://plataforma-integrada.nees.ufal.br/login',
          })
          .then((res) => {
            setUrl(res.data.url)
            setHash(res.data.hash)
          })
          .catch((err) => {
            Alert({
              type: 'error',
              message:
                'Ops! Ocorreu um erro ao conectar-se. Por favor, tente novamente mais tarde',
              autoClose: true,
            })
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getTokens(code: string | unknown, redirect_uri: string) {
    const hash = getHash()

    await AuthGovService()
      .getAuthTokens({
        code,
        hash,
        redirect_uri,
      })
      .then(async (api) => {
        if (api.status === 200) {
          setLocalToken(api.data.access)
          setLocalRefreshToken(api.data.refresh)

          if(getUserProfile() === '' || getUserProfile() === "undefined") {
            setUserData()
            
            listProfiles().then((res)=> {
              setUserProfile(res.results[0].ids[0])
              
              res.results.forEach((profile: any) => {
                if(profile.perfil === "Pre-cadastro") {
                  setUserProfile(profile.ids[0])
                }
              })
            })
          }
        }

        return api
      })
      .catch((err) => {
        AuthGovService()
          .getAuthTokens({
            code,
            hash,
            redirect_uri,
          })
          .then(async (api) => {
            if (api.status === 200) {
              setLocalToken(api.data.access)
              setLocalRefreshToken(api.data.refresh)

              if(getUserProfile() === '' || getUserProfile() === "undefined") {
                setUserData()
                
                listProfiles().then((res)=> {
                  setUserProfile(res.results[0].ids[0])
                  
                  res.results.forEach((profile: any) => {
                    if(profile.perfil === "Pre-cadastro") {
                      setUserProfile(profile.ids[0])
                    }
                  })
                })
              }
            }
    
            return api
          })
          .catch((err) => {
            Alert({
              type: 'error',
              message:
                'Ops! Ocorreu um erro ao conectar-se. Por favor, tente novamente mais tarde',
              autoClose: true,
            })
            setLoading(false)
          })
      })
  }

  return (
    <div
      id='login-0'
      className='grid grid-cols-1 md:grid-cols-2 w-[100%]'
      style={{ height: '100%', display: loading ? 'none' : '' }}
    >
      <div id='login-1' className='mx-[auto] my-[auto] w-[100%] px-[20px]'>
        <p
          id='login-2'
          className='mb-[35px] text-[#002577] text-[20px]'
          style={{ fontWeight: '700' }}
        >
          Plataforma de Recuperação de Aprendizagem e Redução de Abandono
        </p>
        <a
          id='login-3'
          className='text-profile bg-[#083CA6] text-white font-bold py-2 px-6 rounded-full'
          type='button'
          href={url}
        >
          Entrar com gov.br
        </a>

        <div 
          className="flex py-6" 
          style={{ position: 'absolute', bottom: '0'}}>
          <p className='text-sm'><span className='font-bold'>Contato:</span> contato.plataformaintegrada@nees.ufal.br </p>
        </div>
        
      </div>
      <div
        id='login-4'
        className='hidden md:block'
        style={{
          backgroundImage: `url(${loginLeft})`,
          height: '100%',
          backgroundRepeat: 'no-repeat, repeat',
          backgroundSize: 'cover',
        }}
      ></div>
      <Loader loading={loading} />
    </div>
  )
}
